<template>
  <div v-if="invoices && invoices.length" class="grid-widget dash-business-invoices">
    <div class="font-bold">Você tem {{ invoices.length }} pagamento(s) em aberto</div>
    <div class="dash-invoices">
      <table>
        <thead>
        <tr>
          <th>Vencimento</th>
          <th>Valor</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>{{invoice.dataVencimento|formatDate('dd/MM/yyyy')}}</td>
          <td>R$ {{invoice.valor|moeda}}</td>
          <td><a @click="pagar(invoice)">Pagar</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {invoices} from "@/domain/business/services"
import invoiceWindow from "./window/invoiceWindow"

export default {
  name: "BusinessInvoices",
  data () {
    return {
      invoices: null
    }
  },
  mounted() {
    //this.load()
  },
  methods: {
    load () {
      invoices()
          .then(response => {
            console.log(response)
            this.invoices = response.data.result
          })
          .catch(error => {
            console.error(error)
          })
    },
    invoiceWindow,
    pagar (invoice) {
      this.invoiceWindow(invoice.id)
    }
  }
}
</script>
